import { TokenInfo } from "../services/loginService";
import { UserPermissions, UserType } from "../services/usersService";

export function IsParticipant(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.participant.toString();
}

export function IsProgramAdmin(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.programAdmin.toString();
}

export function IsInstitutional(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.institutional.toString();
}

export function IsAccountManager(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.accountManager.toString();
}

export function IsSuperAdmin(tokenInfo: TokenInfo): boolean {
    return tokenInfo && tokenInfo?.user_type?.toString() === UserType.superAdmin.toString();
}

export function HasPermissionCapability(tokenInfo: TokenInfo, userPermission: UserPermissions): boolean {
    if (tokenInfo?.capabilities) {
        const capabilities = parseInt(tokenInfo.capabilities);
        return (capabilities & userPermission) !== 0;
    }
    return false;
}

export function HasPermissionCapabilityProgramAdmin(tokenInfo: TokenInfo, userPermission: UserPermissions): boolean {
    if (!IsProgramAdmin(tokenInfo)) {
        return true;
    } else if (HasPermissionCapability(tokenInfo, userPermission)) {
        return true;
    }
    return false;
}

export function PermissionByProfile(tokenInfo: TokenInfo) {
    let permission = [{
        value: 1,
        label: 'INDIVIDUAL REPORT',
    }, {
        value: 4,
        label: 'VISUALIZE - CHAT BOT',
    }, {
        value: 8,
        label: 'CAN TALK TO CHAT BOT',
    }, {
        value: 16,
        label: 'VISUALIZE - VIRTUAL ACCOUNT',
    }];

    if (IsInstitutional(tokenInfo)) {
        permission = permission.filter(x => [4, 8].includes(x.value));
    }

    return permission;
}